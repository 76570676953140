import baseContextComponent from "@/components/baseContextComponent";
import baseUserContextComponent from "@/components/baseUserContextComponent";
import { Component } from "vue-property-decorator";

@Component({})
export default class RitornoKo extends baseUserContextComponent {
	goTo() {
		if (this.userMustPay) {
			this.$router.push({ name: "myoverins_tuoiPagamenti" });
		} else {
			this.$router.push({ name: "carrelloCategorie" });
		}
	}
}